import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']
  static values = { text: String }

  copy(e) {
    e.preventDefault()

    const textarea = document.createElement('textarea')
    textarea.textContent = this.textValue
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()
    try {
      // Security exception may be thrown by some browsers.
      return document.execCommand('copy')
    }
    catch (e) {
      console.warn('Copy to clipboard failed.', e)
    }
    finally {
      document.body.removeChild(textarea);
    }
  }
}
