import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['element']
  static values = { class: String }

  toggle() {
    this.elementTarget.classList.toggle(this.classValue)
  }
}
