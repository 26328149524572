import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = ['element']
  static values = {
    url: String,
    translations: Object,
    addable: Boolean
  }

  connect() {
    this.elementTarget.classList.remove('invisible')
    const addable = this.addableValue ? v => v : undefined
    new SlimSelect({ select: this.elementTarget, addable, ...this.translationsValue })
  }
}
