import { Controller } from "@hotwired/stimulus"
import { Editor } from '@tiptap/core'
import { Document } from '@tiptap/extension-document'
import { History } from '@tiptap/extension-history'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import { Bold } from '@tiptap/extension-bold'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { Heading } from '@tiptap/extension-heading'
import { Gapcursor } from '@tiptap/extension-gapcursor'
import { Table } from '@tiptap/extension-table'
import { TableRow } from '@tiptap/extension-table-row'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { BulletList } from '@tiptap/extension-bullet-list'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { ListItem } from '@tiptap/extension-list-item'
import { Image } from '@tiptap/extension-image'

export default class extends Controller {
  static targets = ['editor', 'controls', 'input']
  connect() {
    this.controls = {
      bold: this.controlsTarget.querySelector('.bold'),
      italic: this.controlsTarget.querySelector('.italic'),
      link: this.controlsTarget.querySelector('.link'),
      heading: this.controlsTarget.querySelector('.heading'),
      bulletList: this.controlsTarget.querySelector('.bulletList'),
      orderedList: this.controlsTarget.querySelector('.orderedList'),
      image: this.controlsTarget.querySelector('.image'),
    }
    this.tableControls = this.controlsTarget.querySelector('.table-controls')
    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        Document,
        History,
        Paragraph,
        Text,
        Bold,
        Italic,
        Link.configure({
          openOnClick: false,
          linkOnPaste: true
        }),
        Heading.configure({ levels: [3] }),
        Gapcursor,
        Table,
        TableRow,
        TableHeader,
        TableCell,
        BulletList,
        OrderedList,
        ListItem,
        Image
      ],
      // disable Markdown when pasting
      enableInputRules: false,
      enablePasteRules: ['link'],
      content: this.inputTarget.value,
      onUpdate: () => {
        this.setActiveControls()
        const html = this.editor.getHTML()
        this.inputTarget.value = html  === '<p></p>' ? '' : html
      },
      onSelectionUpdate: () => this.setActiveControls(),
    })
  }

  setActiveControls() {
    Object.entries(this.controls).filter(([_, element]) => element).forEach(([name, element]) => {
      element.classList.toggle('active', this.editor.isActive(name))
    })
    if (this.tableControls) {
      this.tableControls.classList.toggle('d-none', !this.editor.isActive('table'))
    }
  }

  bold(e) {
    e.preventDefault()
    this.editor.chain().focus().toggleBold().run()
  }

  italic(e) {
    e.preventDefault()
    this.editor.chain().focus().toggleItalic().run()
  }

  link(e) {
    e.preventDefault()
    const previousUrl = this.editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) return

    if (url === '') {
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    }
  }

  heading(e) {
    e.preventDefault()
    this.editor.chain().focus().toggleHeading({ level: 3 }).run()
  }

  bulletList(e) {
    e.preventDefault()
    this.editor.chain().focus().toggleBulletList().run()
  }

  orderedList(e) {
    e.preventDefault()
    this.editor.chain().focus().toggleOrderedList().run()
  }

  image(e) {
    e.preventDefault()
    const url = window.prompt('URL')
    if (url) {
      this.editor.chain().focus().setImage({ src: url }).run()
    }
  }

  table(e) {
    e.preventDefault()
    this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
  }

  tableHeader(e) {
    e.preventDefault()
    this.editor.chain().focus().toggleHeaderCell().run()
  }

  addColumnAfter(e) {
    e.preventDefault()
    this.editor.chain().focus().addColumnAfter().run()
  }

  deleteColumn(e) {
    e.preventDefault()
    this.editor.chain().focus().deleteColumn().run()
  }

  addRowAfter(e) {
    e.preventDefault()
    this.editor.chain().focus().addRowAfter().run()
  }

  deleteRow(e) {
    e.preventDefault()
    this.editor.chain().focus().deleteRow().run()
  }
}
