// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import { Dropdown } from 'bootstrap'

document.addEventListener('turbo:load', () => {
  Array.from(document.querySelectorAll('.dropdown-toggle')).forEach(n => {
    new Dropdown(n)
  })
})
